import {
  Breadcrumb,
  Button,
  Form,
  Card,
  Radio,
  Input,
  Space,
  DatePicker,
  Spin,
  Skeleton,
} from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import FormEditor from "../../../components/formEditer/FormEditor"
import "react-quill/dist/quill.snow.css"
import "./style.scss"
import { createNews, getNewsDataById, updateNews } from "../../../services/news"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
  openNotificationSuccess,
} from "../../../components/notification"
import moment from "moment"
import { handleDataSentUpdateNews } from "./handleDataSentUpdateNews"
import textAndRules from "../../../utils/textAndRules"

const DetailNews = ({ id }) => {
  const [title, setTitle] = useState("更新通知")
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [dataNews, setDataNews] = useState(null)
  console.log("dataNews", dataNews)
  const [draftStatus, setDraftStatus] = useState(null)
  const [step, setStep] = useState(1)
  const [dataInput, setDataInput] = useState(null)

  const fetchDataNews = async () => {
    setIsLoadingData(true)
    const { response } = await getNewsDataById(id)
    if (response.status == 200) {
      setDataNews(response.data.news)
      setIsLoadingData(false)
    }
  }

  const onFinish = async (values) => {
    const dataSent = handleDataSentUpdateNews(values, draftStatus)
    if (draftStatus == 1) {
      setDataInput(dataSent)
      setStep(2)
      return
    }
    setLoading(true)

    const { response } = await updateNews(id, dataSent)
    if (response.status == 200 || response.status == 201) {
      setLoading(false)
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
      setLoading(false)
    }
  }
  useEffect(() => {
    if (id) {
      fetchDataNews()
    }
  }, [id])
  const submitForm = () => {
    if (form) {
      form.submit()
    }
  }

  const createMarkup = () => {
    return { __html: dataInput?.content }
  }

  const handleSent = async () => {
    setLoading(true)
    const { response } = await updateNews(id, dataInput)
    if (response.status == 200 || response.status == 201) {
      setLoading(false)
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
      setLoading(false)
    }
  }

  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item route="/home">
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-ad-news/?pageCurrent=1&pageSize=10">
                ニュース管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link>更新通知</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex items-center justify-between">
            <Title
              level={3}
              style={{
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {title}
            </Title>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Card
            style={{ paddingBottom: 40, paddingTop: 30 }}
            className={"formItem"}
          >
            <Spin tip="Loading..." spinning={isLoadingData}>
              {isLoadingData && <Skeleton />}
              {dataNews && step == 1 && (
                <Form
                  form={form}
                  initialValues={{
                    time: moment(dataNews.schedule),
                    title: dataNews.title,
                    type:
                      (dataNews.news_type == "notification" && 0) ||
                      (dataNews.news_type == "news" && 1) ||
                      0,
                    content: dataNews.content,
                  }}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item label="公開時間">
                    <Form.Item
                      name={"time"}
                      rules={[
                        {
                          required: true,
                          message: textAndRules.pleaseSelect,
                        },
                      ]}
                    >
                      <DatePicker showTime format={"l HH:mm"} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="タイトル">
                    <Form.Item
                      name={"title"}
                      rules={[
                        {
                          required: true,
                          message: textAndRules.pleaseInput,
                        },
                        {
                          whitespace: true,
                          message: `タイトルは空白文字にすることはできません。`,
                        },
                      ]}
                    >
                      <Input placeholder="新着ニュースのタイトルをここに記入する" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item label="カテゴリ">
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: textAndRules.pleaseInput,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={0}>お知らせ</Radio>
                        <Radio value={1}>ニュース</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>
                  <FormEditor name="content" />
                </Form>
              )}
              {step == 2 && dataInput && (
                <div style={{ textAlign: "start" }}>
                  <Space size={15}>
                    <div className="notifyDate">
                      {moment(dataInput.schedule).format("YYYY/MM/DD")}
                    </div>
                    <div
                      style={{
                        borderRadius: "3px",
                        background:
                          (dataInput.news_type == 1 && "#F0F5FF") ||
                          (dataInput.news_type == 0 && "#FCEFDB") ||
                          "unset",
                        width: "fit-content",
                        padding: "0 10px",
                      }}
                    >
                      {dataInput.news_type == 1 && "ニュース"}
                      {dataInput.news_type == 0 && "お知らせ"}
                    </div>
                  </Space>
                  <h2 style={{ paddingLeft: "1rem" }}>{dataInput.title}</h2>
                  <div style={{ padding: "0 20px", marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={createMarkup()}
                      id="content-news"
                    ></div>
                  </div>
                </div>
              )}
            </Spin>
          </Card>
          {dataNews && step == 1 && (
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
              }}
              size="middle"
            >
              <Button
                style={{ background: "#E4E8EB" }}
                onClick={() => {
                  navigate("/super-ad-news/?pageCurrent=1&pageSize=10")
                }}
              >
                {"ニュース管理へ戻る"}
              </Button>
              <Button
                danger
                style={{ width: 176 }}
                loading={loading}
                onClick={() => {
                  submitForm()
                }}
              >
                {"下書き保存する"}
              </Button>
              <Button
                style={{ width: 176 }}
                onClick={() => {
                  setDraftStatus(1)
                  submitForm()
                }}
                type="primary"
                loading={loading}
              >
                {"確認する"}
              </Button>
            </Space>
          )}
          {step == 2 && (
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 15,
              }}
              size="middle"
            >
              <Button
                style={{ background: "#E4E8EB" }}
                onClick={() => {
                  setStep(1)
                }}
              >
                {"キャンセル"}
              </Button>

              <Button
                style={{ width: 176 }}
                onClick={() => {
                  handleSent()
                }}
                type="primary"
                loading={loading}
              >
                {"送信する"}
              </Button>
            </Space>
          )}
        </div>
      </Content>
    </div>
  )
}
export default DetailNews
